<template>
  <div class="login-container" @keydown="onKeyDown">
    <!-- <div class="background-card"></div> -->

    <div class="container">
      <div class="title">
        <ml-image
          width="60px"
          :isPreviewBeforeUrl="false"
          height="60px"
          :src="logoIcon"
          class="logo"
        ></ml-image>
        <h3 class="text">蓝牙控制管理系统（IOS）</h3>
      </div>

      <!-- 表单 -->
      <div class="login-form">
        <ml-form
          :showBtn="false"
          ref="formAccountRef"
          :model="formAccountData"
          :rules="formAccountRules"
          label-position="top"
          size="large"
          class="inline-block"
        >
          <el-form-item label="手机号" prop="phone">
            <ml-input
              v-model="formAccountData.phone"
              placeholder="请先输入手机号"
            ></ml-input>
          </el-form-item>
          <el-form-item label="密码" prop="password">
            <ml-input
              v-model="formAccountData.password"
              type="password"
              placeholder="请先输入密码"
            ></ml-input>
          </el-form-item>
        </ml-form>

        <!-- 记住账户 -->
        <div class="remember-account">
          <el-checkbox
            v-model="changeRememberAccount"
            label="记住账号"
            size="large"
          />
        </div>

        <ml-button
          useLoading
          class="login-btn"
          type="primary"
          size="large"
          @submit="loginSubmit"
          >登录</ml-button
        >
      </div>
    </div>
  </div>

  <div id="jsi-flying-fish-container" class="container">
  </div>
  <div  class="record">@版权所有 © 2024-2044. 深圳市格瑞普电池有限公司  <a  href="https://beian.miit.gov.cn/" target="_blank" style="color: rgb(132, 147, 161);">备案号：粤ICP备11041765号</a> </div>
</template>

<script setup>
import { STORE_NAME_AUTH_TOKEN } from "@/utils/storageTables";
import { ruleNormalPhone, ruleNormalAccount } from "@/utils/formRules";

import { STORE_NAME_REMENBER_ACCOUNT } from "@/utils/storageTables";

import {
  reactive,
  ref,
  getCurrentInstance,
  watch,
  onMounted,
  computed,
} from "vue";

require("@/utils/fish_canvas.js");

const { proxy } = getCurrentInstance();
const dataStore = proxy.$usePiniaModule("dataStore");

// icon
const logoIcon = require("@/assets/images/logo.png");
// const userIcon = require("@/assets/images/user-icon.png");
// const pwdIcon = require("@/assets/images/password-icon.png");
// const appletQrcodeIcon = require("@/assets/images/applet-qrcode.png");

// 密码加密解密
const strCodeToCode = proxy.$strCodeToCode();

// 记住账户
const changeRememberAccount = ref(false);

// ref
// 账户密码
const formAccountRef = ref();
// 短信
const formAccountData = reactive({
  phone: "",
  password: "",
});
const formAccountRules = {
  phone: ruleNormalPhone(),
  password: [
    {
      message: "请先输入密码",
      required: true,
    },
  ],
};

// 登录
const loginSubmit = proxy.$bypass(async (call) => {
  const testRes = await proxy.$testForm(call, formAccountRef.value);
  if (!testRes) {
    return;
  }

  // console.log(testRes);
  // console.log(formAccountData);

  try {
    const res = await proxy.$storeDispatch(
      "fetchPhonePasswordLogin",
      formAccountData
    );
    const result = res.result;
    // 需要初始 重 定向
    dataStore.initRedirect = true;
    // 赋值 凭证
    proxy.$setItem(STORE_NAME_AUTH_TOKEN, result);
    proxy.$message({
      type: "success",
      message: "登录成功",
    });
    /* 设置缓存数据 */
    // 记住账户
    setRemenberAccount();
    proxy.$delayHandle(() => {
      proxy.$router.replace("/");
    });
  } catch (error) {
    call ? call() : "";
  }
});

/* 记住账户 一周内未操作就失效 */
// 设置缓存
const setRemenberAccount = () => {
  // 拦截
  if (!changeRememberAccount.value) {
    return;
  }
  // console.log(1);
  const currentStamp = Date.now();

  // 一周的时间戳 7 day
  const oneWeekStamp = 1000 * 3600 * 7;

  // 一周后
  const oneWeekStampAfter = currentStamp + oneWeekStamp;

  // 账户
  const { phone } = formAccountData;
  // 使用的账户
  // const useLoginAccount = password || phone;
  // // 密码
  // const password =
  //   tabValue == "account" ? tabFormConfig.value.useFormData.password : "";

  // 拦截
  if (!phone) {
    return;
  }
  const storeData = {
    useLoginAccount: phone,
    endStamp: oneWeekStampAfter,
    rememberAccount: changeRememberAccount.value,
  };

  // console.log(storeData);
  const storeJsonData = JSON.stringify(storeData);
  // console.log(storeJsonData);

  // 存储
  proxy.$setItem(STORE_NAME_REMENBER_ACCOUNT, storeJsonData);
};

// 读取记住账户缓存
const getRemenberAccount = () => {
  const currentStamp = Date.now();
  // 存储数据
  const jsonData = proxy.$getItem(STORE_NAME_REMENBER_ACCOUNT);
  if (!jsonData) {
    return;
  }
  const data = JSON.parse(jsonData);
  const { useLoginAccount, endStamp, rememberAccount } = data;

  // 拦截
  if (!rememberAccount) {
    return;
  }

  // 比较
  const diffResult = currentStamp >= Number(endStamp);
  // 过期
  if (diffResult) {
    proxy.$setItem(STORE_NAME_REMENBER_ACCOUNT, "");
    return;
  }

  // 赋值
  changeRememberAccount.value = rememberAccount;
  formAccountData.phone = useLoginAccount;
  // proxy.$updateParams(formAccountData, useLoginAccount, {
  //   ignoreNull: true,
  // });
};

// 监听回车
const onKeyDown = (e) => {
  if (e.keyCode == 13) {
    loginSubmit();
  }
};

// 初始
const init = () => {
  getRemenberAccount();
};

onMounted(() => {
  init();
});
</script>

<style lang="scss" scoped>
#jsi-flying-fish-container {
  width: 100%;
  position: fixed;
  z-index: 0;
  bottom: 0;
  left: 0;
  display: flex;
}
.record{
  width: auto;
  position: fixed;
  z-index: 1;
  bottom: 10px;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;
  display: flex;
  font-size:12px;
  color:#8493a1
}

.login-container {
  width: 100%;
  height: 100vh;
  background-color: #f5f7fa;
  display: flex;
  flex-direction: column;
  flex-wrap: nowrap;
  justify-content: center;
  align-items: center;
  position: relative;

  // .background-card {
  //   background-image: url(../assets/images/logo.png);
  //   background-size: 100% 100%;
  //   filter: blur(8px);
  //   width: 100%;
  //   height: 100vh;
  //   position: absolute;
  //   z-index: -1;
  // }

  .container {
    width: 420px;
    margin-bottom: 130px;

    .title {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: center;
      margin-bottom: 25px;
      .logo {
        border-radius: 10px;
      }
      .text {
        font-size: 25px;
        line-height: 70px;
        margin-top: 15px;
      }
    }

    .login-form {
      background-color: white;
      padding: 30px 30px 30px;
      border-radius: 6px;
      box-shadow: 1px 2px 10px 1px #0000000d;
    }

    .tool-box {
      display: flex;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 15px;
    }

    .form-icon {
      width: 15px;
      height: 15px;
    }

    .login-btn {
      width: 100%;
      padding: 1px 0;
      height: 38px !important;
      line-height: 38px;
      margin-top: 15px;

      &:hover {
        transition: 300ms;
        background-color: #004dff;
      }
    }

    .tips {
      font-size: 13px;
      color: #3874ff;
      font-weight: 700;
      text-align: center;
      cursor: pointer;

      &:hover {
        text-decoration: underline;
      }
    }

    .other-login {
      overflow: hidden;
      padding-top: 5px;
      .other-text {
        color: #8c92a4;
      }
      .other-tool {
        display: grid;
        justify-items: center;
        .other-item {
          width: 40px;
          height: 40px;
        }
      }
    }
  }
}
</style>

<style lang="scss">
.login-form {
  .el-tabs {
    .el-tabs__nav-wrap {
      &::after {
        height: 1px;
      }

      .el-tabs__nav-scroll {
        .el-tabs__nav {
          width: 100%;
          justify-content: space-between;
          .el-tabs__item {
            padding: 0 !important;
            padding-left: 18px !important;
          }
        }
      }
    }
  }

  .el-form-item {
    margin-bottom: 20px;
  }

  .el-divider__text {
    background-color: #f5f7fa;
  }

  .applet-qrcode-box {
    display: flex;
    flex-direction: row;
    justify-content: center;
    padding: 20px 0;

    img {
      width: 222px;
      height: 222px;
    }
  }
}
</style>
